<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>

          <section class="edp-backend-conditionSearch">
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendPower.seacrhTitle1") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  :placeholder="$t('edpBackendCommon.placeholder')"
                  v-model="searchParams.perName"
                  clearable
                />
              </div>
            </div>

            <div
              class="edp-backend-conditionSearch-square conditionSearch-square-last"
            >
              <el-button size="small" @click="clearSearchParams">{{
                $t("edpBackendCommon.clear")
              }}</el-button>
              <el-button size="small" type="primary" @click="pageInit(1)">{{
                $t("edpBackendCommon.search")
              }}</el-button>
            </div>
          </section>

          <section class="edp-backend-common-table">
            <div class="table-headControlLine">
              <div>
                <el-button type="primary" size="small" @click="createRow">
                  {{ $t("edpBackendCommon.add") }}</el-button
                >
              </div>
            </div>

            <el-table
              stripe
              :data="pageList"
              style="width: 100%"
              v-loading="loading"
            >
              <el-table-column
                prop="perName"
                :label="$t('edpBackendPower.perName')"
              ></el-table-column>

              <el-table-column
                prop="perCode"
                :label="$t('edpBackendPower.perCode')"
              >
              </el-table-column>

              <el-table-column
                prop="createTime"
                :label="$t('edpBackendPower.createTime')"
              >
              </el-table-column>

              <el-table-column
                :label="$t('edpBackendPower.control')"
                width="150"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="setRow(scope.row)">
                    {{ $t("edpBackendCommon.edit") }}</el-button
                  >
                  <el-button type="text" @click="deleteRow(scope.row)">
                    {{ $t("edpBackendCommon.delete") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="pageTotal"
            >
            </el-pagination>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>

    <el-dialog
      class="edp-backend-dialogEl"
      v-if="dialogVisible"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="116px">
        <el-form-item :label="$t('edpBackendPower.perCode')" prop="perCode">
          <el-input
            v-model="form.perCode"
            clearable
            :disabled="dialogType === 'update'"
          ></el-input>
        </el-form-item>

        <el-form-item :label="$t('edpBackendPower.perName')" prop="perName">
          <el-input v-model="form.perName" clearable></el-input>
        </el-form-item>

        <el-form-item :label="$t('edpBackendPower.powerMenu')">
          <el-cascader
            v-model="form.menuIds"
            :placeholder="$t('edpBackendCommon.select')"
            :options="menuIdsOptions"
            :props="{ multiple: true, checkStrictly: true }"
            clearable
          ></el-cascader>
        </el-form-item>

        <el-form-item :label="$t('edpBackendPower.powerFunction')">
          <el-select
            v-model="form.functionIds"
            :placeholder="$t('edpBackendCommon.select')"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="item in functionIdsOptions"
              :key="item.id"
              :label="item.funcName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('edpBackendPower.powerEnter')">
          <el-select
            v-model="form.moduleIds"
            :placeholder="$t('edpBackendCommon.select')"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="item in moduleIdsOptions"
              :key="item.id"
              :label="$i18n.locale === 'cn' ? item.modCnName : item.modEnName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("edpBackendCommon.canale")
        }}</el-button>
        <el-button type="primary" @click="dialogVisible_submit">{{
          $t("edpBackendCommon.define")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "backendPower",
  data() {
    return {
      pageList: [],
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      loading: true,

      searchParams: {
        perName: "",
      },

      form: {
        id: "",
        perName: "",
        perCode: "",
        menuIds: [],
        functionIds: [],
        moduleIds: [],
      },

      menuIdsOptions: [],
      functionIdsOptions: [],
      moduleIdsOptions: [],

      rules: {
        perCode: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendPower.perCode"),
            trigger: "blur",
          },
        ],
        perName: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendPower.perName"),
            trigger: "blur",
          },
        ],
      },

      dialogTitle: "",
      dialogVisible: false,
      dialogType: "",
    };
  },
  methods: {
    ...mapActions({
      powerList: "backendPower/powerList",
      powerCreate: "backendPower/powerCreate",
      powerUpdate: "backendPower/powerUpdate",
      powerDelete: "backendPower/powerDelete",
      powerDetail: "backendPower/powerDetail",

      menuList: "backendMenu/menuList",
      functionList: "backendPower/functionList",
      enterList: "backendPower/enterList",
    }),
    async pageInit(num) {
      this.loading = true;

      if (num) {
        this.currentPage = num;
      }

      let params = Object.assign(
        JSON.parse(JSON.stringify(this.searchParams)),
        {
          current: this.currentPage,
          size: this.pageSize,
        }
      );

      let res = await this.powerList(params);

      this.pageList = res.data.data.records;

      this.pageTotal = res.data.data.total;

      this.loading = false;
    },
    handleCurrentChange(val) {
      this.pageInit(val);
    },
    clearSearchParams() {
      for (var key in this.searchParams) {
        this.searchParams[key] = "";
      }

      this.pageInit(1);
    },
    async createRow() {
      this.dialogType = "";
      this.dialogTitle = this.$t("edpBackendCommon.add");

      this.form.id = "";
      this.form.perCode = "";
      this.form.perName = "";
      this.form.menuIds = [];
      this.form.functionIds = [];
      this.form.moduleIds = [];

      this.dialogVisible = true;
    },
    async setRow(data) {
      let _this = this;

      this.dialogType = "update";
      this.dialogTitle = this.$t("edpBackendCommon.edit");

      let res = await this.powerDetail(data.id);

      this.form.id = res.data.data.id;
      this.form.perCode = res.data.data.perCode;
      this.form.perName = res.data.data.perName;

      this.form.menuIds = [];
      let menuIdsLs = res.data.data.menuIds || [];

      this.form.functionIds = res.data.data.functionIds || [];
      this.form.moduleIds = res.data.data.moduleIds || [];

      function menuDialogInit(data) {
        data.forEach((val) => {
          menuIdsLs.forEach((val2) => {
            let thisIdTotal = val.parentIdTotal
              ? val.parentIdTotal + ""
              : val.id + "";

            if (val.id == val2) {
              _this.form.menuIds.push(thisIdTotal.split(","));
            }
          });

          if (val.children && val.children.length > 0) {
            menuDialogInit(val.children);
          }
        });
      }

      menuDialogInit(this.menuIdsOptions);

      this.dialogVisible = true;
    },
    deleteRow(data) {
      this.$confirm(
        this.$t("edpBackendCommon.actionConfirm"),
        this.$t("edpBackendCommon.noticeTitle"),
        {
          confirmButtonText: this.$t("edpBackendCommon.define"),
          cancelButtonText: this.$t("edpBackendCommon.canale"),
        }
      ).then(async () => {
        let res = await this.powerDelete(data.id);

        if (res.data.success) {
          this.$message({
            type: "success",
            message: this.$t("edpBackendCommon.actionSuccess"),
          });

          this.pageInit(1);
        }
      });
    },
    async dialogVisible_submit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let res = {};

          let formParams = JSON.parse(JSON.stringify(this.form));

          if (this.form.menuIds && this.form.menuIds.length > 0) {
            formParams.menuIds.forEach((val, e) => {
              let newVal =
                this.form.menuIds[e][this.form.menuIds[e].length - 1];
              formParams.menuIds[e] = newVal;
            });
          } else {
            formParams.menuIds = [];
          }

          if (this.dialogType === "update") {
            res = await this.powerUpdate(formParams);
          } else {
            res = await this.powerCreate(formParams);
          }

          if (res.data.success) {
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });

            this.dialogVisible = false;

            this.pageInit(1);
          }
        }
      });
    },
  },
  async mounted() {
    let _this = this;

    this.pageInit();

    let resMenu = await this.menuList({
      current: 1,
      size: 1000,
    });

    let resMenuList = resMenu.data.data;

    this.menuIdsOptions = JSON.parse(JSON.stringify(resMenuList));

    function menuListInit(data) {
      let pageLs = data;
      pageLs.forEach((val, e) => {
        _this.$set(val, "value", val.id);
        _this.$i18n.locale === "cn"
          ? _this.$set(val, "label", val.menuCnName)
          : _this.$set(val, "label", val.menuEnName);

        if (val.children && val.children.length > 0) {
          val.children.forEach((val2) => {
            val.parentIdTotal
              ? (val2.parentIdTotal = val.parentIdTotal + "," + val2.id)
              : (val2.parentIdTotal = val.id + "," + val2.id);
          });
          menuListInit(val.children);
        }
      });
    }

    menuListInit(this.menuIdsOptions);

    let resFun = await this.functionList({
      current: 1,
      size: 1000,
    });

    this.functionIdsOptions = resFun.data.data.records;

    let resEnter = await this.enterList({
      current: 1,
      size: 1000,
    });

    this.moduleIdsOptions = resEnter.data.data.records;
  },
};
</script>
